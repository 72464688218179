import styled, { device } from "@styled";
import { themeGet } from "@styled-system/theme-get";

export const SectionWrap = styled.section`
    position: relative;
    z-index: 1;
    margin-block-end: 120px;
`;

export const LeftBox = styled.div`
    margin-block-end: 40px;
    ${device.large} {
        margin-block-end: 0;
    }

    .about-area__section-title h6 {
        color: ${themeGet("colors.textSecondary")};
        font-weight: 400;
        font-size: ${themeGet("fontSizes.h5")};
        text-transform: unset;
        letter-spacing: unset;
    }

    .about-area__section-title h3 {
        font-size: ${themeGet("fontSizes.h2")};
        line-height: 1.125em;
        font-weight: bold;
    }
`;
