import styled, { device, themeGet } from "@styled";

export const StyledSection = styled.div`
    position: relative;
    isolation: isolate;
    background-color: ${themeGet("colors.primaries.10")};
    padding-block-end: 40px;
    padding-block-start: 40px;

    ${device.medium} {
        padding-block-end: 60px;
        padding-block-start: 60px;
    }
    ${device.large} {
        padding-block-start: 72px;
        padding-block-end: 72px;
    }
`;

export const ImageWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
`;

export const StyledContentWrapper = styled.div`
    position: relative;
`;

export const StyledSubtitle = styled.h6`
    position: relative;
    margin-bottom: 16px;
    color: ${themeGet("colors.textSecondary")};
    font-weight: 400;
    font-size: ${themeGet("fontSizes.h5")};
`;

export const StyledHeading = styled.h3`
    margin-block-end: 10px;
    font-size: ${themeGet("fontSizes.h3")};
    line-height: 1.25em;

    span {
        color: ${themeGet("colors.primary")};
    }

    ${device.large} {
        margin-block-end: 0;
    }
`;

export const StyledButtonsWrapper = styled.div`
    position: relative;
    margin-top: 32px;
`;
