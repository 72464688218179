import styled, { device } from "@styled";
import { themeGet } from "@styled-system/theme-get";

export const PageHeaderWrap = styled.div`
    padding-block-start: 60px;
    padding-block-end: 60px;
    background: ${themeGet("colors.primaries.10")};

    ${device.medium} {
        padding-block-start: 80px;
        padding-block-end: 80px;
    }

    ${device.large} {
        padding-block-start: 120px;
        padding-block-end: 120px;
    }
`;

export const StyledTitle = styled.h1`
    margin-block-end: 16px;
    color: ${themeGet("colors.primary")};
`;

export const StyledDesc = styled.h5`
    font-size: ${themeGet("fontSizes.body")};
    font-weight: 300;
    color: ${themeGet("colors.text")};
`;
