import styled, { device, themeGet } from "@styled";

export const SectionWrap = styled.section`
    padding-block-start: 53px;
    padding-block-end: 19px;

    ${device.medium} {
        padding-block-start: 72px;
        padding-block-end: 25px;
    }
    ${device.large} {
        padding-block-start: 92px;
        padding-block-end: 40px;
    }
`;

export const StyledLeftHeading = styled.h4`
    font-size: ${themeGet("fontSizes.h6")};
    color: ${themeGet("colors.textSecondary")};
    font-weight: 500;
`;
