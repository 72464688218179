import styled, { device, themeGet } from "@styled";

export const HeroWrapper = styled.section`
    padding: 64px 0px;
    background-color: none;
`;

export const HeroTextBox = styled.div`
    max-width: 605px;
    width: 100%;
    padding-inline-start: 0;
    margin-block-start: 50px;
    margin-block-end: 20px;
    margin-inline: auto;
    text-align: center;

    ${device.medium} {
        padding-inline-start: 30px;
        margin-inline-start: auto;
        margin-inline-end: 0;
        margin-block-start: 0;
        margin-block-end: 0;
        text-align: left;
    }

    .cta-button {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;
        max-width: max-content;
    }
`;

export const ImageBoxWrap = styled.div`
    position: relative;
    margin-inline: auto;
    max-width: 370px;

    ${device.medium} {
        padding-block-start: 160px;
        max-width: 100%;
        margin-inline: 0;
    }
    ${device.large} {
        margin-block-start: 20px;
        padding-block-start: 0;
    }
`;

export const ImageBox = styled.div`
    position: relative;
    top: 10px;
    width: 100%;
    max-width: 260px;
    margin-inline: auto;
    ${device.medium} {
        max-width: 100%;
        margin-inline: 0;
    }
`;

export const StyledSubtitle = styled.h5`
    margin-block-end: 20px;
    font-weight: 400;
    font-size: ${themeGet("fontSizes.h5")};
    color: ${themeGet("colors.textSecondary")};
`;

export const StyledTitle = styled.h1`
    margin-block-end: 15px;
    font-size: 3.5rem;
    font-weight: 700;
    line-height: 1em;
`;
