import styled from "@styled";
import BackgroundImage from "gatsby-background-image";

export const AboutServiceWrapStyle = styled.div`
    position: relative;
    padding-top: 64px;
    background: unset;
`;

export const AboutServiceInner = styled(BackgroundImage)`
    position: absolute !important;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-position: top 140px left !important;
    background-size: auto !important;
`;
