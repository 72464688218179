import Anchor from "@ui/anchor";
import { Col, Container, Row } from "@ui/wrapper";
import { ButtonType, HeadingType, TextType } from "@utils/types";
import PropTypes from "prop-types";
import React from "react";
import {
    ContactInfoBox,
    SectionWrap,
    StyledHeading,
    StyledHeadingWrapper,
    StyledInfoContent,
    StyledInfoTitle,
    StyledText,
} from "./style";

const ContactArea = ({ data }) => {
    return (
        <SectionWrap>
            <Container>
                <Row alignItems="center">
                    <Col lg={7}>
                        <StyledHeadingWrapper>
                            {data?.headings?.[0] && (
                                <StyledHeading
                                    as={data.headings[0].level}
                                    dangerouslySetInnerHTML={{
                                        __html: data.headings[0].content,
                                    }}
                                />
                            )}
                            {data?.headings?.[1] && (
                                <StyledText
                                    dangerouslySetInnerHTML={{
                                        __html: data.headings[1].content,
                                    }}
                                />
                            )}
                        </StyledHeadingWrapper>
                    </Col>
                    <Col
                        lg={{
                            size: 5,
                            offset: 1,
                        }}
                    >
                        {data.texts?.map((contact, index) => {
                            const { icon, label, content, link } = contact;

                            return (
                                <ContactInfoBox key={index}>
                                    {label && (
                                        <StyledInfoTitle>
                                            {label}
                                        </StyledInfoTitle>
                                    )}

                                    <StyledInfoContent>
                                        {icon && <i className={icon} />}

                                        {content && (
                                            <Anchor
                                                path={link}
                                                color="secondary"
                                                hover={{
                                                    layout: 2,
                                                    color: "secondary",
                                                }}
                                            >
                                                {content}
                                            </Anchor>
                                        )}
                                    </StyledInfoContent>
                                </ContactInfoBox>
                            );
                        })}
                    </Col>
                </Row>
            </Container>
        </SectionWrap>
    );
};

ContactArea.propTypes = {
    data: PropTypes.shape({
        headings: PropTypes.arrayOf(PropTypes.shape(HeadingType)),
        texts: PropTypes.arrayOf(PropTypes.shape(TextType)),
        buttons: PropTypes.arrayOf(PropTypes.shape(ButtonType)),
    }),
};

export default ContactArea;
