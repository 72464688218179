import { breakpoints } from "./index";

export const theme = {
    colors: {
        primary: "#086AD8",
        text: "#696969",
        textSecondary: "#757575",
        heading: "#333333",
        border: "#ededed",
        borderTwo: "#f8f8f8",
        borderDash: "#dddddd",
        white: "#ffffff",
        black: "#000000",
        secondary: "#d2a98e",
        success: "#38cb89",
        info: "#17a2b8",
        warning: "#ffc107",
        danger: "#dc3545",
        light: "#f8f9fa",
        background: "#ffffff",
        gray: {
            50: "#FAFAFA",
            100: "#F5F5F5",
            200: "#EEEEEE",
            300: "#E0E0E0",
            400: "#BDBDBD",
            500: "#9E9E9E",
            600: "#757575",
            700: "#616161",
            800: "#424242",
            900: "#212121",
        },
        primaries: {
            10: "#eff3f9",
            50: "#e3f3ff",
            100: "#bbe0ff",
            200: "#8eceff",
            300: "#5dbaff",
            400: "#32abff",
            500: "#009cff",
            600: "#008dfe",
            700: "#027bea",
            800: "#0869d8",
            900: "#0d49b9",
        },
        silver: "#ababab",
        silverTwo: "#cccccc",
        kimberly: "#6d70a6",
        spring: "#F6F2ED",
        pattens: "#def0ff",
        yellow: "#ffb944",
        whiteSmoke: "#f5f5f5",
    },
    lineHieghts: {
        body: 1.74,
        heading: 1.41,
    },
    fonts: {
        body: "CerebriSans",
        heading: "CerebriSans",
        fontAwesomePro: "Font Awesome 5 Pro",
        fontAwesomeBrand: "Font Awesome 5 Brands",
    },
    fontSizes: {
        body: "16px",
        h1: "56px",
        h2: "48px",
        h3: "40px",
        h4: "34px",
        h5: "24px",
        h6: "18px",
    },
    fontWeights: {
        body: 400,
        heading: 700,
    },
    radii: {
        sm: "3px",
        md: "5px",
        lg: "8px",
        xl: "16px",
        round: "50%",
        pill: "500px",
    },
    shadows: {
        default: "0 0 40px rgba(51, 51, 51, 0.1)",
        sm: "0 0 20px rgba(51, 51, 51, 0.1)",
        md: "0 18px 40px rgba(51, 51, 51, 0.1)",
        lg: "0 0 40px 5px rgba(51, 51, 51, 0.1)",
        xl: "0 41px 43px rgba(51, 51, 51, 0.07)",
        input: "0 0 0 0.2rem rgb(1 104 250 / 25%)",
    },
    transition: "all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1)",
    breakpoints: [...breakpoints],
};
