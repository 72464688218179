import styled, { device, themeGet } from "@styled";

export const StyledSection = styled.div`
    background-color: ${themeGet("colors.primary")};
    padding-block-end: 40px;
    padding-block-start: 40px;
    &:before,
    &:after {
        background-position: top 35% right -68px;
        background-size: auto;
    }
    ${device.medium} {
        padding-block-end: 60px;
        padding-block-start: 60px;
    }
    ${device.large} {
        padding-block-start: 80px;
        padding-block-end: 80px;
    }

    .cta-04__button {
        color: ${themeGet("colors.primary")};
    }

    .cta-04__button:not(:first-child) {
        color: white;
    }
`;

export const StyledHeading = styled.h3`
    margin-block-end: 10px;
    color: #fff;
    span {
        color: ${themeGet("colors.secondary")};
    }
    ${device.large} {
        margin-block-end: 0;
    }
`;

export const StyledBG = styled.div`
    position: absolute;
    right: 0;
    top: 0;
    z-index: -1;
`;
