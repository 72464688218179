import styled from "@styled";
import { themeGet } from "@styled-system/theme-get";

export const BannerArea = styled.div`
    padding-block-start: 80px;
    padding-block-end: 80px;
    background: ${themeGet("colors.primaries.10")};
`;

export const StyledBannerTextWrap = styled.div`
    max-width: 750px;
`;

export const StyledTitle = styled.h1`
    color: ${themeGet("colors.primary")};
    margin-block-end: 15px;
`;

export const StyledTagline = styled.h5`
    color: ${themeGet("colors.text")};
    font-weight: 400;
`;
