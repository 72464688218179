import styled, { device, themeGet } from "@styled";

export const SectionWrap = styled.div`
    padding-block: 80px;
    background-color: ${themeGet("colors.primary")};
    background-size: auto !important;
    background-position: top right;
    &:before,
    &:after {
        background-size: auto !important;
        background-position: top right;
    }

    ${device.medium} {
        padding-block: 60px;
    }

    ${device.large} {
        padding-block: 80px;
    }
`;

export const StyledHeadingWrapper = styled.div`
    position: relative;

    &:before {
        position: absolute;
        content: "";
        left: 0px;
        top: 50%;
        width: 2px;
        height: 94%;
        transform: translateY(-50%);
        background-color: ${themeGet("colors.secondary")};
    }

    h3 {
        font-size: ${themeGet("fontSizes.h3")};
        font-weight: bold;
        line-height: 1.125em;
    }

    p {
        margin-top: 24px;
        font-weight: 300;
        line-height: 1.36em;
    }
`;

export const StyledHeading = styled.h3`
    font-weight: 600;
    color: #fff;
    position: relative;
    line-height: 1.4;
    padding-inline-start: 34px;
`;

export const StyledText = styled.p`
    margin-block-start: 15px;
    font-size: 18px;
    color: #fff;
    margin-inline-start: 34px;
`;

export const ContactInfoBox = styled.div`
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    color: white;

    &:not(:last-child) {
        margin-bottom: 24px;
    }

    ${device.large} {
        margin-block-start: 0;
    }
`;

export const StyledInfoTitle = styled.h6`
    margin-bottom: 8px;
    color: inherit;
    font-weight: normal;
    font-size: ${themeGet("fontSizes.body")};
`;

export const StyledInfoContent = styled.div`
    position: relative;
    display: flex;
    align-items: center;

    .icon {
        margin-right: 24px;
        color: inherit;
        font-size: 24px;
    }

    a {
        color: inherit;
        font-weight: bold;
        font-size: 32px;
        text-decoration: unset;
    }
`;
