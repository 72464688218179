import styled, { device } from "@styled";
import themeGet from "@styled-system/theme-get";

export const CaseStudyWrapper = styled.div`
    padding-block-start: 60px;
    padding-block-end: 60px;
    padding-inline: 15px;
    background-position: bottom left;
    background-size: auto;
    ${device.medium} {
        padding-block-start: 80px;
        padding-block-end: 80px;
    }
    ${device.large} {
        padding-block-start: 100px;
        padding-block-end: 100px;
    }

    .case-study-01__section-title h6 {
        font-size: ${themeGet("fontSizes.h5")};
        text-transform: none;
        letter-spacing: unset;
        color: ${themeGet("colors.textSecondary")};
    }

    .case-study-01__section-title h3 {
        font-size: ${themeGet("fontSizes.h2")};
        line-height: 1.25em;
    }

    .swiper-autoheight .swiper-slide {
        height: 520px;
    }

    .swiper-autoheight .swiper-slide > div {
        border-radius: ${themeGet("radii.lg")};
    }

    .box-large-image-02__button-wrapper {
        border: none;
    }
`;
