import styled, { themeGet } from "@styled";

export const BoxImgMediaWrap = styled.figure`
    position: relative;
    z-index: 2;
    margin-block-start: 0;
    margin-block-end: 2px;
    width: 128px;
    height: 128px;
`;

export const BoxImgInner = styled.div`
    box-shadow: none;
    background: white;
    border-radius: ${themeGet("radii.lg")};
    overflow: hidden;
    position: relative;
    height: 100%;
    transition: ${themeGet("transition")};
    padding: 32px 24px;
`;

export const BoxImgContent = styled.div`
    text-align: left;
`;

export const HeadingWrap = styled.h5`
    margin-top: 16px;
    margin-bottom: 24px;
    font-weight: 500;
    font-size: ${themeGet("fontSizes.h5")};
    line-height: 1.25;
`;

export const TextWrap = styled.p`
    margin-bottom: 0;
    font-weight: 300;
`;

export const BoxImgBtnWrap = styled.div`
    position: relative;
    z-index: 1;
    margin-top: 24px;

    a.button {
        font-size: ${themeGet("fontSizes.body")};
        font-weight: 400;

        &:hover {
            transform: none;
            background: none;
            color: ${themeGet("colors.primary")};
        }
    }
`;

export const BoxImgWrapper = styled.div`
    height: 100%;
    &:hover {
        ${BoxImgInner} {
            transform: translateY(-5px);
        }
    }
`;
