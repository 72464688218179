import styled, { color, device } from "@styled";
import themeGet from "@styled-system/theme-get";

export const ServicesWrapper = styled.section`
    padding-block-start: 60px;
    padding-block-end: 50px;
    background: ${themeGet("colors.gray.100")};

    ${device.medium} {
        padding-block-start: 80px;
        padding-block-end: 70px;
    }
    ${device.large} {
        padding-block-start: 100px;
        padding-block-end: 90px;
    }
    ${color}

    .it-service-05__section-title h6 {
        font-size: ${themeGet("fontSizes.h5")};
        color: ${themeGet("colors.textSecondary")};
        text-transform: unset;
        letter-spacing: unset;
    }

    .it-service-05__section-title h3 {
        font-size: ${themeGet("fontSizes.h2")};
        font-weight: bold;
    }

    .it-service-05__motto-text a {
        border-color: ${themeGet("colors.primary")};
        font-weight: normal;
        line-height: 1em;
    }
`;

export const SectionBottom = styled.div`
    margin-block-start: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    ${device.small} {
        flex-direction: row;
    }
`;
