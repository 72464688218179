import Button from "@ui/button";
import { Col, Container, Row } from "@ui/wrapper";
import { ButtonType, HeadingType } from "@utils/types";
import PropTypes from "prop-types";
import React from "react";
import { StyledHeading, StyledSection } from "./style";

const CTAArea = ({ data }) => {
    return (
        <StyledSection>
            <Container>
                <Row
                    alignItems="center"
                    textAlign={["center", null, null, "left"]}
                >
                    <Col xl={8} lg={7}>
                        {data?.headings?.[0] && (
                            <StyledHeading
                                as={data.headings[0]?.level}
                                dangerouslySetInnerHTML={{
                                    __html: data.headings[0]?.content,
                                }}
                            />
                        )}
                    </Col>
                    <Col xl={4} lg={5} className="text-center">
                        {data?.buttons?.map(({ id, content, ...rest }) => (
                            <Button
                                className="cta-04__button"
                                key={id}
                                m="7px"
                                {...rest}
                            >
                                {content}
                            </Button>
                        ))}
                    </Col>
                </Row>
            </Container>
        </StyledSection>
    );
};

CTAArea.propTypes = {
    data: PropTypes.shape({
        headings: PropTypes.arrayOf(PropTypes.shape(HeadingType)),
        buttons: PropTypes.arrayOf(PropTypes.shape(ButtonType)),
    }),
};

export default CTAArea;
