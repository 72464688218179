import Button from "@ui/button";
import Image from "@ui/image";
import { Col, Container, Row } from "@ui/wrapper";
import { ButtonType, HeadingType, ImageType } from "@utils/types";
import PropTypes from "prop-types";
import { default as React } from "react";
import {
    ImageWrapper,
    StyledButtonsWrapper,
    StyledContentWrapper,
    StyledHeading,
    StyledSection,
    StyledSubtitle,
} from "./style";

const CTAArea = ({ data }) => {
    return (
        <StyledSection>
            <Container>
                <Row
                    alignItems="center"
                    textAlign={["center", null, null, "left"]}
                >
                    {data?.images?.[0] && (
                        <Col xl={4} lg={5}>
                            <ImageWrapper>
                                <Image
                                    src={data.images[0].src}
                                    alt={data.images[0].alt}
                                />
                            </ImageWrapper>
                        </Col>
                    )}

                    {data?.headings?.[0] && (
                        <Col xl={8} lg={7}>
                            {data.headings[0] && (
                                <StyledContentWrapper>
                                    {data.headings[0]?.subtitle && (
                                        <StyledSubtitle>
                                            {data.headings[0]?.subtitle}
                                        </StyledSubtitle>
                                    )}

                                    <StyledHeading
                                        as={data.headings[0]?.level}
                                        dangerouslySetInnerHTML={{
                                            __html: data.headings[0]?.content,
                                        }}
                                    />

                                    {data.buttons?.length && (
                                        <StyledButtonsWrapper>
                                            {data.buttons.map(
                                                (
                                                    { id, content, ...rest },
                                                    index
                                                ) => (
                                                    <Button
                                                        key={id ?? index}
                                                        {...rest}
                                                    >
                                                        {content}
                                                    </Button>
                                                )
                                            )}
                                        </StyledButtonsWrapper>
                                    )}
                                </StyledContentWrapper>
                            )}
                        </Col>
                    )}
                </Row>
            </Container>
        </StyledSection>
    );
};

CTAArea.propTypes = {
    data: PropTypes.shape({
        headings: PropTypes.arrayOf(PropTypes.shape(HeadingType)),
        images: PropTypes.arrayOf(PropTypes.shape(ImageType)),
        buttons: PropTypes.arrayOf(PropTypes.shape(ButtonType)),
    }),
};

export default CTAArea;
