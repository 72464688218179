import styled, { device, themeGet } from "@styled";

export const SectionWrap = styled.section``;

export const GridWrap = styled.div`
    border-inline-end: 1px solid ${themeGet("colors.border")};
    display: flex;
    flex-wrap: wrap;
`;

export const Grid = styled.div`
    max-width: 100%;
    flex-basis: 100%;
    padding-block-start: 60px;
    padding-block-end: 60px;
    padding-inline-start: 10px;
    padding-inline-end: 10px;
    position: relative;
    text-align: center;

    ${device.medium} {
        max-width: 50%;
        flex-basis: 50%;
    }
    ${device.large} {
        max-width: 25%;
        flex-basis: 25%;
    }
    ${device.xlarge} {
        padding-block-start: 100px;
        padding-block-end: 100px;
        padding-inline-start: 25px;
        padding-inline-end: 25px;
    }

    &:before {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 1px;
        background: ${themeGet("colors.border")};
    }

    .fun-fact-area-01__counter-item {
        display: inline-block;
        max-width: 128px;
        text-align: center;

        h5 {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 2.5em;
            color: ${themeGet("colors.textSecondary")};
            font-size: ${themeGet("fontSizes.md")};
            letter-spacing: unset;
            line-height: 1.36em;
        }

        div span {
            font-weight: bold;
        }

        h6 {
            display: flex;
            align-items: center;
            justify-content: center;
            color: ${themeGet("colors.text")};
            text-transform: unset;
            font-size: ${themeGet("fontSizes.body")};
            letter-spacing: unset;
            font-weight: 300;
        }
    }
`;
