import styled, { device } from "@styled";
import { themeGet } from "@styled-system/theme-get";

export const TeamWrapper = styled.section`
    padding-block-start: 53px;
    ${device.medium} {
        padding-block-start: 72px;
    }
    ${device.large} {
        padding-block-start: 89px;
    }

    .team-02__section-title h3 {
        font-size: ${themeGet("fontSizes.h3")};
    }
`;
