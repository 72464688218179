import styled, { css, device } from "@styled";
import { themeGet } from "@styled-system/theme-get";

export const SectionWrap = styled.div`
    ${({ $space }) =>
        $space === 1 &&
        css`
            padding-block-start: 53px;
            padding-block-end: 60px;
            ${device.medium} {
                padding-block-start: 72px;
                padding-block-end: 80px;
            }
            ${device.large} {
                padding-block-start: 89px;
                padding-block-end: 80px;
            }
        `}
    ${({ $space }) =>
        $space === 2 &&
        css`
            padding-block-start: 35px;
            padding-block-end: 60px;
            ${device.medium} {
                padding-block-start: 53px;
                padding-block-end: 75px;
            }
            ${device.large} {
                padding-block-start: 94px;
                padding-block-end: 68px;
            }
        `}
`;

export const StyledImageContainer = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 360px;
    width: 100%;
    background: ${themeGet("colors.primaries.10")};
`;

export const ImageWrapper = styled.figure`
    position: relative;
    z-index: 2;
    width: 320px;
    height: 320px;
`;
