import styled, { color, device, themeGet } from "@styled";
import React from "react";

export const SectionWrap = styled(({ bg, ...rest }) => <section {...rest} />)`
    background-color: none;
    padding-block: 32px;

    ${device.medium} {
        padding-block: 48px;
    }
    ${device.large} {
        padding-block: 64px;
    }
    ${color}

    .it-solution__section-title h6 {
        text-transform: capitalize;
        letter-spacing: unset;
        color: ${themeGet("colors.textSecondary")};
        font-size: ${themeGet("fontSizes.h5")};
        font-weight: 400;
    }

    .it-solution__section-title h3 {
        line-height: 1em;
        font-size: ${themeGet("fontSizes.h3")};
    }
`;
