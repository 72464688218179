import BoxImage from "@components/box-image/layout-10";
import MottoText from "@components/motto";
import Button from "@ui/button";
import SectionTitle from "@ui/section-title";
import { Col, Container, Row } from "@ui/wrapper";
import {
    ButtonType,
    ItemType,
    MottoType,
    SectionTitleType,
} from "@utils/types";

import PropTypes from "prop-types";
import React from "react";
import { SectionBottom, ServicesWrapper } from "./style";

const ServiceArea = ({ data, ...rest }) => {
    return (
        <ServicesWrapper {...rest}>
            <Container>
                {data?.section_title && (
                    <SectionTitle
                        className="it-service-05__section-title"
                        mb={["30px", null, "40px"]}
                        subtitle={data.section_title?.subtitle}
                        title={data.section_title?.title}
                    />
                )}

                {data?.items && (
                    <Row>
                        {data.items?.map((item) => {
                            return (
                                <Col lg={4} md={6} key={item.id}>
                                    <BoxImage
                                        title={item.title}
                                        image={item.featured_image}
                                        desc={item.excerpt}
                                        path={`/it-service/${item.slug}`}
                                    />
                                </Col>
                            );
                        })}
                    </Row>
                )}

                {data?.motto && (
                    <MottoText
                        className="it-service-05__motto-text"
                        mt={["42px", null, "56px"]}
                        text={data.motto?.text}
                        linkText={data.motto?.linkText}
                        path={data.motto?.link}
                    />
                )}

                {data?.buttons && (
                    <SectionBottom>
                        {data.buttons?.map(({ id, content, ...restProps }) => (
                            <Button key={id} m="10px" {...restProps}>
                                {content}
                            </Button>
                        ))}
                    </SectionBottom>
                )}
            </Container>
        </ServicesWrapper>
    );
};

ServiceArea.propTypes = {
    data: PropTypes.shape({
        section_title: PropTypes.shape(SectionTitleType),
        items: PropTypes.arrayOf(PropTypes.shape(ItemType)),
        motto: PropTypes.shape(MottoType),
        buttons: PropTypes.arrayOf(PropTypes.shape(ButtonType)),
    }),
};

export default ServiceArea;
