import styled, { themeGet } from "@styled";

export const BoxImgMediaWrap = styled.figure`
    position: relative;
    width: 220px;
    height: 220px;
    border-radius: ${themeGet("radii.round")};
    margin-block-start: 0;
    margin-block-end: 2px;
    margin-inline: auto;
    text-align: center;
    z-index: 2;

    .gatsby-image-wrapper {
        margin-inline: auto;
    }
`;

export const BoxImgInner = styled.div`
    box-shadow: none;
    background: ${themeGet("colors.gray.100")};
    border-radius: ${themeGet("radii.lg")};
    overflow: hidden;
    position: relative;
    height: 100%;
    transition: ${themeGet("transition")};
    padding-block-start: 80px;
    padding-block-end: 80px;
    padding-inline: 24px;
`;

export const BoxImgContent = styled.div`
    text-align: center;
`;

export const HeadingWrap = styled.h5`
    margin-top: 16px;
    margin-bottom: 24px;
    font-weight: 500;
    font-size: ${themeGet("fontSizes.h5")};
    line-height: 1.25;
`;

export const TextWrap = styled.p`
    margin-bottom: 0;
    font-weight: 300;
`;

export const BoxImgBtnWrap = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    height: 56px;
    line-height: 55px;
    text-align: center;

    &:before {
        background-color: ${themeGet("colors.primary")};
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        transform: translateY(100%);
        transition: ${themeGet("transition")};
        z-index: -1;
    }

    a.button {
        width: 100%;
        height: 100%;
        font-size: ${themeGet("fontSizes.body")};
        font-weight: 400;
    }
`;

export const BoxImgWrapper = styled.div`
    height: 100%;
    &:hover {
        ${BoxImgInner} {
            transform: translateY(-5px);
        }
        ${BoxImgBtnWrap} {
            &:before {
                transform: translateY(0);
            }
            a {
                color: #fff;
            }
        }
    }
`;
